import React, { Fragment, useContext } from "react";
import { Link } from "gatsby";

import logo from "../../assets/img/virsat-logo-light.svg";
import phoneImg from "../../assets/img/icons/phone.svg";
import moveTop from "../../assets/img/icons/move-top.svg";
import emailImg from "../../assets/img/icons/email.svg";
import locationImg from "../../assets/img/icons/location.svg";
import fbImg from "../../assets/img/icons/social/facebook-icon.svg";
import instagramImg from "../../assets/img/icons/social/instagram-icon.svg";
import linkedinImg from "../../assets/img/icons/social/linkedin-icon.svg";
import twitterImg from "../../assets/img/icons/social/twitter-icon.svg";
import youtubeImg from "../../assets/img/icons/social/youtube-icon.svg";
// import deliveryPdf from "../../assets/pdf/VIRSAT-Terms-and-Conditions-of-Delivery.pdf";
// import purchasePdf from "../../assets/pdf/VIRSAT-Terms-and-Conditions-of-Purchase.pdf";
import CookieConsent from "../common/CookieConsent";
import {
  GlobalDispatchContext,
  GlobalStateContext,
  ACTIONS,
} from "../../context/GlobalContextProvider";

export default function Footer() {
  let curDate = new Date();
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const smoothScroll = (target, duration) => {
    const anchor = document.querySelector(target);
    const anchorPosition = anchor.getBoundingClientRect().top;
    let startPosition = window.pageYOffset;
    let distance = anchorPosition - startPosition;
    let startTime = null;

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      let timeElapsed = currentTime - startTime;
      let run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    function ease(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  };

  return (
    <Fragment>
      <div
        className='move-to-top'
        aria-hidden
        onClick={() => smoothScroll("#top", 500)}
      >
        <img src={moveTop} alt='Move to Top' />
      </div>

      <footer className='main-site-footer'>
        {!state.showPageLoading &&
        (
          <>
            <section className='main-container site-footer-default'>
              <div className='row top-pad bottom-pad '>
                <div className='column fj-center fd-col'>
                  <div
                    className='logo-container'
                    data-aos='fade-up'
                    data-aos-duration='600'
                  >
                    <Link to='/'>
                      <img src={logo} alt='VIRSAT logo' />
                    </Link>
                  </div>
                  <ul className='contact-us-ul'>
                    <li
                      className='contact-us-li'
                      data-aos='fade-up'
                      data-aos-duration='600'
                    >
                      <div className='icon-container'>
                        <img src={locationImg} alt='Location' />
                      </div>
                      <div className='contact-details'>
                        <span className='contact-name'>Address</span>
                        <br />
                        <p className='light'>
                          <span className='country'>USA: </span>1400 Preston Road, #400 / Plano, Texas 75093
                        </p>
                        <p className='no-mar light'>
                          <span className='country'>UAE: </span>Tamouh Tower, Bldg. 12, 22nd Floor, Marina Square, Al Reem Island, Abu Dhabi
                        </p>
                        {/* <p className='no-mar light'>
                          <span className='country'>Netherlands: </span>Plantagekade
                          10, Postcode 1018 ZV, Amsterdam
                        </p> */}
                      </div>
                    </li>
                    <li
                      className='contact-us-li'
                      data-aos='fade-up'
                      data-aos-duration='600'
                    >
                      <div className='icon-container'>
                        <img src={phoneImg} alt='Phone' />
                      </div>
                      <div className='contact-details'>
                        <span className='contact-name'>Phone</span>
                        <br />
                        <a href='tel:+97126436640' className='contact-value'>
                          +971 2 643 6640
                        </a>
                      </div>
                    </li>
                    <li
                      className='contact-us-li'
                      data-aos='fade-up'
                      data-aos-duration='600'
                    >
                      <div className='icon-container'>
                        <img src={emailImg} alt='Email' />
                      </div>
                      <div className='contact-details'>
                        <span className='contact-name'>Email</span>
                        <br />
                        <a href='mailto:info@virsat.com' className='contact-value'>
                          info@virsat.com
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='column fd-col'>
                  <header data-aos='fade-up' data-aos-duration='600'>
                    Menu
                  </header>
                  <ul data-aos='fade-up' data-aos-duration='600'>
                    <li className='nav-link-li'>
                      <Link className='nav-link-li-a' to='/'>
                        Home
                      </Link>
                    </li>
                    <li className='nav-link-li'>
                      <Link className='nav-link-li-a' to='/about/'>
                        About
                      </Link>
                    </li>
                    <li className='nav-link-li'>
                      <Link className='nav-link-li-a' to='/articles/'>
                        Articles
                      </Link>
                    </li>
                    <li className='nav-link-li'>
                      <Link className='nav-link-li-a' to='/animations/'>
                        Animations
                      </Link>
                    </li>

                    <li className='nav-link-li'>
                      <Link className='nav-link-li-a contact' to='/contact/'>
                        Contact
                      </Link>
                    </li>

                    {/* <li className='nav-link-li'>
                      <Link className='nav-link-li-a contact' to='/partner'>
                        Become Our Partner
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className='column fd-col'>
                  <header data-aos='fade-up' data-aos-duration='600'>
                    Products
                  </header>
                  <ul
                    className='nav-submenu-ul first'
                    data-aos='fade-up'
                    data-aos-duration='600'
                  >
                    <li className='nav-submenu-li'>
                      <Link to='/products/?cat=All' className='light'>
                        All Products
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link to='/products/?cat=Animations' className='light'>
                        Animations
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link to='/products/?cat=Apps' className='light'>
                        Apps
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link to='/products/?cat=Culture Change' className='light'>
                        Culture Change
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link to='/products/?cat=Games' className='light'>
                        Games
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link
                        to='/products/?cat=Learning from Incidents'
                        className='light'
                      >
                        Learning from Incidents
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link to='/products/?cat=Life Saving Rules' className='light'>
                        Life Saving Rules
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link to='/products/?cat=Training' className='light'>
                        Training
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <div className='main-container site-footer'>
              <div className='row'>
                <div className='column'>
                  <div className='logo-container'>
                    {/* <Link className="footer-logo-a" to="/">
                      <img src={logo} alt="VIRSAT Logo" />
                    </Link> */}
                    <p className='light copyright no-mar small'>
                      Copyright © 2021-{curDate.getFullYear()}. All rights reserved.
                    </p>
                    <nav className='footer-links'>
                      <ul className='footer-links-ul'>
                        <li className='footer-links-li'>
                          <Link to='/privacy-policy/'>Privacy Policy</Link>
                        </li>
                        <li className='footer-links-li'>
                          <Link to='/cookie-policy/'>Cookie Policy</Link>
                        </li>
                        <li className='footer-links-li'>
                          <Link
                            to='#!'
                            onClick={() =>
                              dispatch({ type: ACTIONS.SHOW_COOKIE_PREFERENCES })
                            }
                          >
                            Cookie Preferences
                          </Link>
                        </li>
                        <li className='footer-links-li'>
                          <a
                            href='https://secure.virsat.com/pdf/Terms%20and%20Conditions%20(Purchase).pdf'
                            title='VIRSAT Conditions of Purchase'
                            target='_blank'
                            rel='noreferrer'
                          >
                            Purchase Terms and Conditions
                          </a>
                        </li>
                        <li className='footer-links-li'>
                          <a
                            href='https://secure.virsat.com/pdf/Terms%20and%20Conditions%20(Delivery).pdf'
                            title='VIRSAT Conditions of Delivery'
                            target='_blank'
                            rel='noreferrer'
                          >
                            Delivery Terms and Conditions
                          </a>
                        </li>
                        <li className='footer-links-li'>
                          <Link to='/report-security-issue/'>Report a security issue</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <nav className='footer-social'>
                    <ul className='social-ul'>
                      <li className='social-li'>
                        <a
                          href='https://www.facebook.com/VirtualRealitySafetyTraining'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            className='fb-icon'
                            src={fbImg}
                            alt='VIRSAT Facebook Page'
                          />
                        </a>
                      </li>
                      <li className='social-li'>
                        <a
                          href='https://twitter.com/virsat_llc'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={twitterImg} alt='VIRSAT Twitter Page' />
                        </a>
                      </li>
                      <li className='social-li'>
                        <a
                          href='https://www.linkedin.com/company/virtualrealitysafetytraining'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={linkedinImg} alt='VIRSAT LinkedIn Page' />
                        </a>
                      </li>
                      <li className='social-li'>
                        <a
                          href='https://www.instagram.com/virtualrealitysafetytraining/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={instagramImg} alt='VIRSAT Instagram Page' />
                        </a>
                      </li>
                      <li className='social-li'>
                        <a
                          href='https://www.youtube.com/channel/UCwA2cT9UaygadY4esRPSkrw'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={youtubeImg} alt='VIRSAT YouTube Page' />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </>
        )}
        
      </footer>
      <CookieConsent />
    </Fragment>
  );
}
